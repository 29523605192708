import { Article } from './article'
import { CommentImage, SizeImage } from './image'
import { User } from './user'

export interface Comment {
  article: Article
  [key: string]: any
  id: number
  message: string
  user_id: number
  create_date: string
  delete_status: number
  root: number
  l_key: number
  r_key: number
  level: number
  model: number
  best: number
  reply_user_id: number | null
  parent_id: number
  user: User
  projectId: number
  images: CommentImage[]
}

export enum CommentsStatus {
  NEW_COMMENT = 0,
  PUBLISHED = 1,
  DELETE = 3,
  DEFERRED = 5,
  PUBLISH_DELAYED = 6,
}

export interface CommentTabs {
  title: string
  icon: string
  slug: number
  filter: string
  id: number
  disabled: boolean
  count?: number
}
