import { MetaItem } from './meta'
import { Platform } from './platform'

export interface ImageSource {
  value: string
  text: string
  access?: Platform[]
}

export interface Image {
  id: number
  url: string
  from?: 'media-library'
}

export interface SizeImage extends Image {
  width: number
  height: number
}

export enum ImageAspectRatio {
  SOCIAL = 1.9,
  DETAIL = 1.5,
}

export interface ImagePurposeProportion {
  id: number
  proportion: string
}

export interface ImagePurpose {
  id: 0
  name: string
  code: string
  proportions: ImagePurposeProportion[]
}

export enum ImageTransformConstructor {
  STUB_DETAIL_IMAGE = 'newsTemplate',
  SOCIAL = 'social',
  STUB_SOCIAL = 'newsTemplateSocial',
}

export enum ImageTransformType {
  LOGO = 1,
  NEWS = 2,
  AFFILIATE = 3,
}

export enum ImageTransformAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum ImageTransformVAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

export interface ImageTransformMeta {
  constructorConfigs: Record<
    ImageTransformConstructor,
    {
      background: MetaItem<number>[]
      type: MetaItem<number>[]
      text: string
    }
  >
  'settings.constructor': ImageTransformConstructor[]
}

export enum ImageTransformModelId {
  Article = 1,
  Test = 2,
  RichHtmlBlock = 3,
  Contacts = 4,
  Menu = 5,
  AccessUser = 6,
  Recipe = 7,
  Tag = 8,
  Author = 9,
  RecipeIngredient = 10,
  RecipeStep = 11,
  Poll = 12,
  Gallery = 13,
  Video = 14,
  PollChoice = 15,
  TestQuestion = 16,
  TestQuestionAnswer = 17,
  GalleryPhoto = 18,
}

export interface ImageTransform {
  id: number | null
  url?: string
  crop_h: number | null
  crop_w: number | null
  crop_x: number | null
  crop_y: number | null
  image_id: number | null
  image: Image | null
  settings?: {
    constructor?: ImageTransformConstructor
    background: number
    text: string
    type?: ImageTransformType
    align?: ImageTransformAlign
    'v-align'?: ImageTransformVAlign
  }
  image_proportion_id?: number | null
  image_purpose_id?: number | null
  item_id?: number | null
  model?: string | null
  model_id?: number | null
  constructedImage?: {
    id: number
    url: string
  } | null
}

export interface CommentImage {
  id: number
  animated_gif: number
  broken: number
  height: number
  path: string
  pivot: {
    image_id: number
    item_id: number
  }
  url: string
  width: number
}
