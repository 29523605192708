import { PollChoice } from './pollChoice'

export enum PollTypeCode {
  SIMPLE = 'simple',
  TWO_OPTIONS = 'two_options',
  ARTICLES = 'articles',
}

export interface Poll {
  active: number
  anonymous: number
  anchor: string
  description: ''
  extra_fields?: PollExtraFields
  id: number
  image_id: number
  interest_id: number
  multiple_answers: number
  name: string
  pinned: number
  prize_description: string
  prize_photo_id: number
  type: number
  choices: PollChoice[]
}

export type PollEditData = Omit<
  Poll,
  | 'extra_fields?'
  | 'image_id'
  | 'multiple_answers'
  | 'pinned'
  | 'prize_photo_id'
  | 'anonymous'
  | 'choices'
>

export type PollActiveField = Pick<Poll, 'active'>

export interface PollExtraFields {
  anketaBirthday: null
  anketaCheckBox1: null
  anketaCheckBox1Required: null
  anketaCheckBox2: null
  anketaCheckBox2Required: null
  anketaCheckBox3: null
  anketaCheckBox3Required: null
  anketaCheckBox4: null
  anketaCheckBox4Required: null
  anketaCity: null
  anketaEmail: null
  anketaLastName: null
  anketaMessage: null
  anketaName: null
  anketaPhone: null
  anketaSubtitle: null
  anketaTitle: null
  isAnketa: null
  isPrize: null
  labelOfGetPrizeButton: null
  prizeArticleUrl: null
}
