export enum WidgetType {
  GALLERY = 'gallery',
  BUTTON = 'button',
}

export default interface BrandWidget {
  brand_id: number
  type: WidgetType
  data: {
    id?: number | null
    url?: string
  }
  title: string
  description: string
  id?: number
}
