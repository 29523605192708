import { User } from '@sentry/vue'
import { CommentImage, SizeImage } from './image'

export interface AwardsApplication {
  id: number
  status: number
  text: string
  name: string
  email: string
  user: User
  photos: CommentImage[]
  extra_fields: {
    story: AwardsStory
    topic: {
      photoIds: number[]
      poll: { name: string; choices: string[] }
    }
  }
}

export interface AwardsStory {
  name: string
  nomination: string
  phoneNumber: string
  photoIds: number[]
  socialLinks: Record<string, string>
  videoUrl: string
}

export interface AwardsJury {
  active: 1 | 0
  created_at: string
  id: number
  image: SizeImage | null
  image_id: null | number
  name: string
  preview_text: string
  text: string
  updated_at: string
}

export enum ModarationStatus {
  NEW = 0,
  PUBLISHED = 1,
  DELAY = 2,
  REJECTED = 3,
}
