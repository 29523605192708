import { SizeImage } from './image'

export interface MediaLibraryItem {
  image_id: number
  image: SizeImage | null
}

export interface MediaLibraryImageItem extends MediaLibraryItem {
  image_id: number
  id: number
}

export enum MediaLibraryEssence {
  ARTICLE = 'article',
  ISSUE = 'issue',
  EXPERT = 'expert',
  PODCAST_EPISODE = 'podcast_episode',
  PODCAST = 'podcast',
  RECIPE = 'recipe',
  CONTEST = 'contest',
  FEATURE_ARTICLE = 'feature_article',
  TECHNICAL = 'technical',
  TEST = 'test',
}

export interface MediaLibrary {
  essence: MediaLibraryEssence
  essenceId: number
}

export interface MediaLibraryImage extends MediaLibrary {
  image_id: number
}

export interface MediaLibraryParams extends MediaLibrary {
  count: number
  page: number
}
