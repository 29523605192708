import { SizeImage, ImageTransform } from './image'

export interface Podcast {
  name?: string
  code?: string
  description?: string
  square_image_id?: number | null
  image?: SizeImage | null
  format?: PodcastType
  email?: string
  author?: string
  owner?: string
  active_from?: string
  active?: boolean
  adult?: boolean
  category?: PodcastCategory
  id: number
  lang?: string
  timezone?: string
  rss?: string
  category_id: number | null
  squareImage?: ImageTransform | null
  distributions?: PodcastDistributions[]
}

export enum PodcastType {
  EPISODIC = 'episodic',
  SERIAL = 'serial',
}

export interface PodcastMeta {
  fields: {
    categories: PodcastCategory[]
    format: string[]
  }
}

export interface PodcastCategory {
  active: number
  code: string
  id: number
  name: string
  parent_id: number | null
  sub_categories: PodcastCategory[]
}

export interface PodcastDistributions {
  channel: string
  id: number
  podcast_id: number
  url: string
  icon?: string
  label?: string
}
