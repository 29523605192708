import { Tag } from '@/types/tag'
import { Section } from '@/types/section'
import { ImageTransform, Image as TImage } from './image'

export enum FeatureType {
  small = 1,
  middle = 2,
  big = 3,
  section = 4,
  tag = 5,
}

export interface Feature {
  active: number
  active_from?: string | null
  active_to?: string | null
  c_time: string
  extra_fields: {
    featureHex?: string | null
    backgroundType?: string | null
  } | null
  image_id: number | null
  m_image_id: number | null
  logo_image_id: number | null
  logo_image: TImage | null
  backgroundType: null
  featureHex: null
  id: number
  image: ImageTransform | null
  link?: string
  logo_disabled: number
  logoImage: ImageTransform | null
  mImage: ImageTransform | null
  name: string
  new_window: number
  placement: number
  sections?: Section[] | number[]
  section_name: string
  title?: string
  type: FeatureType
  tags?: (Tag | number)[]
  target: number
  new_year: 0 | 1
}

export interface Image {
  crop_h: number
  crop_w: number
  crop_x: number
  crop_y: number
  id: number
  image: {
    id: number
    path: string
    width: number
    animated_gif: number
    broken: number
    height: number
    url: string
  }
  image_id: number
  image_proportion_id: number
  image_purpose_id: number
  item_id: number
  model_id: number
  settings: null
  url: string
  use_fullsize: number
}
