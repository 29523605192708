import { GalleryPhoto } from './galleryPhoto'
import { Tag } from './tag'

export enum DisplayType {
  TILE = 'tile',
  VERTICAL = 'vertical',
}

export interface Gallery {
  active: number
  create_date: string
  description: string
  id: number
  is_fullscreen: boolean
  is_preview: boolean
  name: string
  old_id: number
  horizontal: number
  photos: GalleryPhoto[]
  tag: Tag | null
  tag_id: number | null
  widget_type?: string
}

export type GalleryEditData = Omit<Gallery, 'old_id' | 'create_date' | 'tag'>
