export enum SubjectSlug {
  ISSUE = 'issue',
  SUGGESTION = 'suggestion',
  ACCESS_TO_ADMIN = 'accessToAdmin',
  ACCESS_TO_NETWORKS = 'accessToNetworks',
  ACCESS_TO_PARSER = 'accessToParser',
  ACCESS_TO_METRICS = 'accessToMetrics',
  OTHER = 'other',
}

export interface SupportSubject {
  id: number
  name: string
  slug: SubjectSlug
}
