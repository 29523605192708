import { Field } from '../action'
import { Star } from '@/types/star'
import { ImageTransform } from '../image'

export interface Video {
  age_limit: number
  broken: number
  category: string
  code: string
  created_at: string
  content_url: string
  copyright: string
  description: null | string
  distribution_disable: number
  duration: number
  duration_nice: string
  external_id: string
  external_library: null | string
  external_type: string
  height: number
  id: number
  monetization_disable: number
  name: string
  name_imported: string
  no_ad: number
  orientation: number
  preview_image_id: number | null
  previewImage: ImageTransform | null
  vertical_image: Image
  recommends_disable: number
  section_id: null
  thumbnail: string
  updated_at: string
  upload_date: string
  upload_status: number | null
  vertical_image_id: number
  video_partner_id: null | number
  views: number
  likes: number
  width: number
  personal: number
  single_template: number
  distributions: DistributionItem[]
  star?: Partial<Star> | null
  stars?: Partial<Star>[]
  star_id?: Partial<Star> | (number | undefined | null)
  sp_as_new_tab: 0 | 1
  sp_link: string
  has_video_article: boolean
  prev_views_count: number
}

export interface Image {
  animated_gif?: number
  broken?: number
  height?: number
  id: number
  path?: string
  width?: number
  url: string
}

export interface OrientationItem {
  name: string
  code: string
  value: number
}

export enum VideoOrientation {
  UNKNOW = 0,
  HORIZONTAL = 1,
  VERTICAL = 2,
  SQUARE = 3,
  ERROR = 4,
}

export enum VideoUploadStatus {
  LOADING_ON_HOST = 0,
  PROCESSED = 1,
  COMPLITED = 2,
  ERROR_LOADING = 3,
  ERROR_CONVERATION = 4,
}

export enum FramesStatus {
  NOT_PROCESSED = 0,
  TO_PROCESSING = 1,
  IN_PROCESS = 2,
  COMPLITED = 3,
  ERROR = 4,
  NOT_REQUIRE_PROCCESSING = '',
}
export interface DistributionItem {
  description: string
  external_id?: string
  external_type: string
  group_id: Field[] | string
  id?: number
  name: string
  no_comments: number
  publish_on_wall: number
  status?: number
  tags: string
  video_id: number
  enabled: boolean
  disabled: boolean
  error: boolean
}
