import { SizeImage } from './image'

export enum SectionIdStar {
  im = 0,
  cosmo = 85,
  goodhouse = 1005,
  popmech = 100187,
  grazia = 579,
  bazaar = 777,
  mhealth = 100949,
  esquire = 3257,
  robb = 217,
  girlspower = 0,
}

export interface Biograhy {
  id: number
  active: number
  birthday: string
  occupations: number[]
  city: null
  code: string
  state: null
  death_date: string
  gender: null
  height: number
  weight: number
  education: null
  location: null
  familyStatus: null
  image: SizeImage | null
  image_id: number | null
  married_with: string
  parents: null
  children: null
  social_fb_link: null | string
  social_imdb_link: null | string
  social_inst_link: null | string
  social_kinopoisk_link: null | string
  social_tiktok_link: null | string
  social_twitter_link: null | string
  social_vk_link: null | string
  social_wiki_link: null | string
  social_youtube_link: null | string
  star_city_id?: number
  star_state_id?: number
  star_family_status_id?: number
  full_name: string
  cyr_name: string
  meta_title: string
  meta_description: string
  meta_keywords: string
  works: Work[]
}

export interface Work {
  additional_name?: null
  id: number
  name: string
  star_id: number
  type: number
  year: null | number
  isMultiPaste?: boolean
}

export type SocialKeys =
  | 'social_fb_link'
  | 'social_imdb_link'
  | 'social_inst_link'
  | 'social_kinopoisk_link'
  | 'social_tiktok_link'
  | 'social_twitter_link'
  | 'social_vk_link'
  | 'social_wiki_link'
  | 'social_youtube_link'
