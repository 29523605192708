import { Image } from './image'
import { VideoOrientation } from './widget/video'

export enum BlockCode {
  ARTICLE_SCROLL = 'article-scroll',
  VIDEO_SCROLL = 'video-scroll',
  VIDEO_DRAGGING = 'video-dragging',
  RANDOMIZER = 'randomizer',
  VIDEO = 'video',
  VIDEO_FIXED = 'video-fixed',
  HOME = 'home',
  AWARDS_FIXED = 'video-awards',
}

export enum GenderSlug {
  ALL = 'all',
  FEMALE = 'female',
  MALE = 'male',
}

export enum GeoSlug {
  ALL = 'all',
  NOT_RUSSIA = 'not_russia',
  ONLY_RUSSIA = 'only_russia',
}

export enum IncomeSlug {
  LESS_AVERAGE = 'lessAverage',
  AVERAGE = 'average',
  MORE_AVERAGE = 'moreAverage',
}

export interface BlockArticleTargetings {
  sections: { id: number; name: string; code: string }[]
  geo: GeoSlug
  gender: GenderSlug
  age: number | null
  income: IncomeSlug | null
}

export interface BlockArticle {
  id: number
  block_id: number
  article_id: number
  views: number
  days_limit: number
  stat_views: number
  distribute_to_date: string
  days_limit_count: number
  position: number
  type: number
  targetings: BlockArticleTargetings
  article: {
    id: number
    active: number
    name: string
    code: string
    type: number
    active_from: string
    preview_text: null | string
    production_id: number
    detailImage: Image
    section: {
      parent: {
        id: number
        active: number
        name: string
        code: string
      }
      id: number
      active: number
      name: string
      code: string
    }
    videos?: {
      orientation: VideoOrientation
    }[]
  }
}

export type ShortBlockArticle = Pick<
  BlockArticle,
  | 'id'
  | 'stat_views'
  | 'views'
  | 'article_id'
  | 'days_limit'
  | 'days_limit_count'
  | 'block_id'
  | 'distribute_to_date'
  | 'targetings'
> & { article?: { id: number; name: string; code?: string } }

export interface Block {
  id: number
  title: string
  type: number
}

export interface BlockWithoutPositions extends Block {
  code: Exclude<BlockCode, BlockCode.VIDEO_DRAGGING>
  blockArticles: BlockArticle[]
}

export interface BlockWithPositions extends Block {
  code:
    | BlockCode.VIDEO_DRAGGING
    | BlockCode.VIDEO_FIXED
    | BlockCode.AWARDS_FIXED
  type: number
  blockArticles: {
    position: number
    blockArticles: BlockArticle[]
  }[]
}
