import { BlockWithPositions, BlockWithoutPositions } from './block'
import { Feature } from './feature'
import { ImageTransform } from './image'

export enum BoardSlug {
  FEATURES = 'features',
  CONTESTS = 'contests',
  FIXED_ARTICLES = 'fixedArticles',
  PINNED_AT_SECTION = 'pinnedAtSection',
  PINNED_ARTICLES = 'pinnedArticles',
  POPBLOCKS = 'popblocks',
  VIDEO_ARTICLES = 'videoArticles',
  DEFAULT = 'default',
  PINNED_VIDEO_ARTICLES = 'pinnedVideoArticles',
  FIXED_VIDEO_ARTICLES = 'fixedVideoArticles',
}

export interface Fixed {
  contests?: Contests[]
  fixedArticles?: FixedArticles[]
  pinnedAtSection?: FixedArticles[]
  pinnedArticles?: PinnedArticles[]
  popblocks?: Popblocks[]
  videoArticles?: BlockWithPositions
  features?: Feature[]
  pinnedVideoArticles?: PinnedArticles[]
  fixedVideoArticles?: BlockWithPositions
  homeArticles?: BlockWithoutPositions
}

export interface Popblocks {
  position: number
  popblocks: Popblock[]
}

export interface Popblock {
  active: number
  as_new_tab: number
  create_date: string
  description: string
  ending_date: string
  id: number
  image_id: number | null
  link: string
  pinAtPosition: number
  title: string
  user_id: number
  viewlink: string
  image?: ImageTransform | null
  imageSquare?: ImageTransform | null
}

export interface Contests {
  active: number
  active_from: string
  active_to: string
  brand_name: string
  code: null | string
  description: string
  id: number
  image_id: number
  image_square_id: number
  name: string
  pixel_code: string
  placeholder: number
  section_id: number
  url: string
}

export interface Article {
  id: number
  active: number
  name: string
  code: string
  type: number
  active_from: string
  status_id: number
  fixed_position: number
  draft_id: number
  section: {
    id: number
    active: number
    name: string
    code: string
    parent: {
      id: number
      active: number
      name: string
      code: string
    }
  }
}

export interface FixedArticles {
  positions: {
    position: number
    articles: Article[]
  }[]
  section: {
    id: number
    name: string
    parent_id: number | null
  }
}

export interface PinnedArticles {
  position: number
  articles: Article[]
}

export interface FixedContest {
  active: number
  as_new_tab: number
  active_from: string | null
  active_to: string | null
  name: string
  link: string
  viewlink: string
  loading: boolean
  image: ImageTransform | null
  image_id: number | null
  imageSquare: ImageTransform | null
  image_square_id: number | null
}

export enum VideoBlockType {
  HORIZONTAL = 1,
  VERTICAL = 2,
  DISABLED = 3,
}
