export enum Model {
  ARTICLE = 'article',
  RECIPE = 'recipe',
}

export enum ModelId {
  ARTICLE = 1,
  RECIPE = 7,
  USER = 6,
  NATIVE_POST = 23,
  VIDEO = 14,
  EXPERT = 26,
}
